// extracted by mini-css-extract-plugin
export var azure = "Technologies-module--azure--t7yf3";
export var azure_devops = "Technologies-module--azure_devops--TVuYW";
export var azure_svg = "Technologies-module--azure_svg--fc2vw";
export var bit_bucket = "Technologies-module--bit_bucket--63NBj";
export var bit_bucket_svg = "Technologies-module--bit_bucket_svg--WibHp";
export var brands_display = "Technologies-module--brands_display--o8jgt";
export var cloud_flare = "Technologies-module--cloud_flare--jdYdG";
export var cloud_flare_svg = "Technologies-module--cloud_flare_svg--u+6Nt";
export var column_container = "Technologies-module--column_container--4Dtx3";
export var container = "Technologies-module--container--+veqM";
export var github = "Technologies-module--github--HbHJW";
export var higher = "Technologies-module--higher--HrjdN";
export var lower = "Technologies-module--lower--Sen6C";
export var svg = "Technologies-module--svg--xGOaN";
export var terraform = "Technologies-module--terraform--GIPe5";
export var terraform_svg = "Technologies-module--terraform_svg--XXKDn";