import * as React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../compontents/Layout/Layout';
import ProcessNew from '../compontents/ProcessNew/ProcessNew';
import Technologies from '../compontents/Technologies/Technologies';
import { header_image } from './devops.module.scss';

type DevOpsPageProps = {
    location: any;
};

const DevOpsPage = ({ location }: DevOpsPageProps) => {
    return (
        <Layout location={location}>
            <div data-testid="devops-container">
                <Helmet>
                    <title>DevOps and Cloud Management | Streamline Your Software Development</title>
                </Helmet>

                {/*Intro Header*/}
                <div className="row mainText m-5">
                    <div className="col-md-6">
                        <img src="../../images/devops/3d-devops-infinity.jpg" className={header_image}></img>
                    </div>
                    <div className="col-md-6">
                        <h2>DevOps And Cloud Setup</h2>
                        <p>
                            Streamline your software development. Get faster time to market, improved performance,
                            increased efficiency, with less errors and downtime. We&apos;ll help with automated
                            processes and a continuous delivery pipeline.{' '}
                        </p>
                        <a className="button-primary" href="/contact-us">
                            Contact us to get started
                        </a>
                        <div className="mt-3" style={{ fontSize: '1rem' }}>
                            <a href="#cloud">Cloud Solutions</a> | <a href="#process">Our Process</a>
                        </div>
                    </div>
                </div>
                {/*end Intro Header*/}

                <div className="container">
                    {/*Struggle section*/}
                    <h3>Don&apos;t Let Your Development Process Get In Your Way</h3>
                    <div className="row mb-5">
                        <div className="col-md-6">
                            <p>
                                Many companies are looking to cut down the time it takes to{' '}
                                <strong>build, test,</strong> and <strong>deploy</strong> software. Traditional
                                development methods lead to long wait times between big releases, with added risk of
                                errors and downtime.
                            </p>
                        </div>
                        <div className="col-md-6 px-5">
                            <img
                                className="img-fluid"
                                src="../../images/devops/dev-time-spiral.png"
                                alt="long development time"
                            />
                        </div>
                    </div>
                    {/* endStruggle section*/}

                    {/*solutions section*/}
                    <h4>DevOps Is The Answer</h4>
                    <p>
                        DevOps is an approach to software development that emphasizes collaboration, automation, and
                        continuous improvement.
                        <br />
                        This approach is increasingly being adopted by organizations of all sizes and industries.
                    </p>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <h4>Whole Company Benefits</h4>
                            <ul>
                                <li>Tighter cohesion between development and other teams</li>
                                <li>Continuous delivery of high-quality software</li>
                                <li>Mitigate errors and production downtime</li>
                                <li>Faster time to market</li>
                                <li>Cut costs and be more effective</li>
                            </ul>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <h4>Software Team Benefits</h4>
                            <ul>
                                <li>Automate time-consuming manual processes</li>
                                <li>Fast and continuous development</li>
                                <li>Automated testing</li>
                                <li>Increased stability and consistency</li>
                                <li>Improved monitoring for easier troubleshooting</li>
                            </ul>
                        </div>
                        <div className="col-lg-4 bgsection p-3" style={{ border: '1px solid #0092c5' }}>
                            <div className="row">
                                <div className="col-7 my-auto">
                                    <h5>Could a DevOps Process Help You Speed To Market?</h5>
                                    <p>Get a free brief to learn more</p>
                                    <a className="button-primary" href="/speedtomarket">
                                        Free Document
                                    </a>
                                </div>
                                <div className="col-5">
                                    <a href="/speedtomarket">
                                        <img className="img-fluid" src="../images/speed_to_market_document.png" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p className="mainText emphasis mt-5 mb-0 center">
                        DevOps practices speed the development and delivery process.
                    </p>
                    <p className="center">
                        Developers can deploy code up to 20 times a day with no worries using an automated process with
                        DevOps!
                    </p>
                </div>
                {/*end container*/}
                {/*end solutions section*/}

                {/*guide section*/}
                <div className="bgsection p-5 mt-5">
                    <div className="container my-2 mainText">
                        <h3>Get The DevOps Help You Need With SquareHook</h3>
                        <p>
                            We understand the need for speed and efficiency in development. It feels terrible if your
                            code won’t be implemented for months. It feels terrible when errors cause major setbacks.
                        </p>
                        <p>
                            SquareHook can <strong>get you automated</strong> with a continuous deployment pipeline.
                            We’ll help you <strong>&quot;get IT done!&quot;</strong>
                        </p>
                        <a className="button-primary" href="/contact-us">
                            Get A Free Consultation
                        </a>
                        <a className="mx-3" href="tel:801-309-1959">
                            or call 1-801-309-1959
                        </a>
                        <p></p>
                    </div>
                </div>
                {/*end guide section*/}

                {/*Cloud section*/}
                <div id="cloud" className="anchor center mainText m-5">
                    <h2>Cloud Infrastructure</h2>
                    <p>Switch to the cloud to streamline and scale your business</p>
                    <img src="../../images/devops/cloud-arrows.png" className={header_image}></img>
                </div>

                <div className="container mb-5">
                    <h3 className="center mb-0">Could You Benefit From A Cloud-Based Infrastructure?</h3>
                    <p className="center">Ask yourself these questions:</p>
                    <div className="row mb-5">
                        <div className="col-lg-6">
                            <ul>
                                <li>
                                    Are you a small-mid size business, looking for enterprise-level technology without
                                    the investment in on-site servers and infrastructure?
                                </li>
                                <li>
                                    Are you a start-up that needs the ability to quickly scale-up and scale-down as the
                                    needs of business change?
                                </li>
                                <li>Do you have teams working remotely that need easy access?</li>
                                <li>
                                    Are you tired of juggling a load of 3rd party apps with overlapping redundant
                                    features, important missing features, and compatibility issues?{' '}
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-6 center">
                            <img className="img-fluid" src="../../images/devops/cloud-computing.jpg" />
                        </div>
                    </div>
                    <p className="mainText">
                        If you answered yes to any of these questions, you should switch to a cloud infrastructure! The
                        cloud offers greater flexibility, scalability, cost savings, and easy access to the things your
                        company needs from anywhere in the world.
                    </p>
                    <h4 className="center mt-5">SquareHook can modernize and simplify your process</h4>
                    <p className="mainText">
                        We know your solution should be catered to your company&apos;s specific needs. Whether through
                        creating continuous delivery pipelines, automated scaling, or locking down your security needs,
                        our team of specialists will help achieve your goals.
                    </p>
                </div>
                {/*end container*/}

                <Technologies></Technologies>

                {/*our process*/}
                {/*using a cardgroup is not responsive on small screens right now. check break points*/}
                <div id="process" className="anchor container mt-5 pt-5">
                    <h3 className="center">How It Works</h3>
                    <ProcessNew />
                </div>
                {/*end our process*/}

                {/*CTA section*/}
                <div className="bgsection p-5 center">
                    <div className="container mainText mb-5">
                        <h2>Start By Contacting us</h2>
                        <p>
                            Stop wasting time and money and start enjoying the benefits of a faster and automated
                            workflow.
                        </p>
                        <a className="button-primary" href="/contact-us">
                            Get A Free Consultation
                        </a>
                        <p></p>
                    </div>
                </div>
                {/*end CTA section*/}
            </div>
        </Layout>
    );
};

export default DevOpsPage;
